<template>
  <div class="tutorial-faq">
    <div class="faq-page-title">
      <p class="faq-title">FAQ</p>
      <p class="faq-subtitle">常见问题</p>
    </div>
    <div class="rows faq">
      <template v-if="qType === '1'">
        <p class="faq-type">登录问题</p>
        <p class="subtitle">Q1. 使用什么浏览器登录游戏</p>
        <p>A1: iOS系统建议使用safari浏览器</p>
        <p class="indent">2: Android系统建议使用Chrome浏览器</p>
        <p class="subtitle">Q2. 地区限制问题如何解决</p>
        <p>
          A1:下载Outline进行翻墙（<a href="https://getoutline.org/zh-CN/"
        >Outline</a
        >下載）
        </p>
        <p class="indent">2: 使用我们提供的密钥进行翻墙</p>
        <p class="subtitle">Q3. 无法登录网站如何查询自己的IP地址</p>
        <p>A1: 打开以下连接即可查询IP地址</p>
        <p class="link">
          <a href="https://check.agin.cc/">https://check.agin.cc/</a>
        </p>
        <p class="indent">2: 联络客服并提供IP地址进行原因排查</p>
      </template>
      <template v-else-if="qType === '2'">
        <p class="faq-type">注册问题</p>
        <p class="subtitle">Q1. 如何加入本网站？</p>
        <p>
          A1: 您可以直接点选
          "立即加入"，确实填写数据后，可立即登记成为本网站会员。
        </p>
      </template>
      <template v-else-if="qType === '3'">
        <p class="faq-type">设置问题</p>
        <p class="subtitle">Q1. 如果忘记密码怎么办？</p>
        <p>
          A1:
          妳可点击首页忘记密码功能，填写妳当初留下的邮箱，即可取回妳当初设定的密码。当妳无法收取邮件时，妳也可以联系24小时在线客服人员谘询协助取回妳的账号密码。
        </p>
        <p class="subtitle">Q2. 如何重置密码</p>
        <p class="step">A1: ① 点击进入会员中心</p>
        <img v-src="require('./assets/01.png')" alt="" class="snap" />
        <p class="step">②选择修改密码</p>
        <img v-src="require('./assets/02.png')" alt="" class="snap" />
        <p class="step">③依照顺序填写栏位，完成后点击提交</p>
        <img v-src="require('./assets/03.png')" alt="" class="snap" />
        <p class="indent">2: 联系客服辅助修改密码</p>
      </template>
      <template v-else-if="qType === '4'">
        <p class="faq-type">游戏连线问题</p>
        <p class="subtitle">Q1. 如何下载游戏App</p>
        <p>A1: 点击游戏大厅App下载</p>
        <img v-src="require('./assets/04.png')" alt="" class="snap" />
        <p class="subtitle">Q2. 游戏app无法打开或闪退</p>
        <p>A1: iOS设备游戏App无法打开或闪退需重新安装App</p>
        <p class="indent">
          2:
          Android设备游戏App无法打开或闪退，需把App删除并重新安装网站提供的最新App。
        </p>
        <p class="subtitle">Q3. 游戏过程中发生卡顿或无响应</p>
        <p>A1: 刷新浏览器</p>
        <p class="indent">2: 清理浏览器缓存</p>
        <p class="subtitle">Q4. 网站无法正常打开</p>
        <p>A1: 联络客服索取最新网址或备用网址</p>
        <p class="indent">
          2:
          Android设备游戏App无法打开或闪退，需把App删除并重新安装网站提供的最新
          App。
        </p>
      </template>
      <template v-else-if="qType === '5'">
        <p class="faq-type">游戏规则问题</p>
        <p class="subtitle">Q1. 我在哪里可以找到游戏规则？</p>
        <p>
          A1:
          在未登入前，您可以在游戏的最外层看到"游戏规则"选项，清楚告诉您游戏的玩法、规则及派彩方式。
          在游戏窗口中,也有"规则"选项，让您在享受游戏乐趣的同时，可以弹跳窗口随时提醒您游戏规则。
        </p>
        <p class="subtitle">Q2. 妳们的游戏会用多少副牌？</p>
        <p>A1: 在百家乐我们会用8副牌，其他游戏则会根据其性质有所调整。</p>
        <p class="subtitle">Q3. 您们何时会洗牌?</p>
        <p>
          A1:
          所有纸牌游戏，当红的洗牌记号出现或游戏因线路问题中断时便会进行重新洗牌。
        </p>
        <p class="subtitle">Q4. 我的注码的限制是多少？</p>
        <p>
          A1: 从最低注单10元以上即可投注，
          您的注码会根据您的存款有所不同，以及您挑选的游戏不同而有所区别。
        </p>
      </template>
      <template v-else-if="qType === '6'">
        <p class="faq-type">存取款问题</p>
        <p class="subtitle">Q1. 我可以直接在网络上存款吗？</p>
        <p>A1: 可以，本网站提供多种在线存款选择，详情请联系客服。</p>
      </template>
      <template v-else>
        <p>
          <router-link class="faq-type" to="faq/1">登录问题</router-link>
        </p>
        <p>Q1. 使用什么浏览器登录游戏</p>
        <p>Q2. 地区限制问题如何解决</p>
        <p>Q3. 无法登录网站如何查询自己的IP地址</p>
        <p>
          <router-link class="faq-type" to="faq/2">注册问题</router-link>
        </p>
        <p>Q1. 如何加入本网站？</p>
        <p>
          <router-link class="faq-type" to="faq/3">设置问题</router-link>
        </p>
        <p>Q1. 如果忘记密码怎么办？</p>
        <p>Q2. 如何重置密码</p>
        <p>
          <router-link class="faq-type" to="faq/4">游戏连线问题</router-link>
        </p>
        <p>Q1. 如何下载游戏App</p>
        <p>Q2. 游戏app无法打开或闪退</p>
        <p>Q3. 游戏过程中发生卡顿或无响应</p>
        <p>Q4. 网站无法正常打开</p>
        <p>
          <router-link class="faq-type" to="faq/5">游戏规则问题</router-link>
        </p>
        <p>Q1. 我在哪里可以找到游戏规则？</p>
        <p>Q2. 妳们的游戏会用多少副牌？</p>
        <p>Q3. 您们何时会洗牌?</p>
        <p>Q4. 我的注码的限制是多少？</p>
        <p>
          <router-link class="faq-type" to="faq/6">存取款问题</router-link>
        </p>
        <p>Q1. 我可以直接在网络上存款吗？</p>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'tutorial-faq',
  data() {
    return {
      qType: undefined,
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(val) {
        const type = _.get(val, 'params.type')
        this.qType = type
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.tutorial-faq {
  .faq-page-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .faq-title {
      color: #5ccde5;
      font-weight: 400;
      font-size: 4rem;

      @include md {
        margin-top: 1rem;
      }
    }

    .faq-subtitle {
      margin-top: 1rem;
      font-size: 1rem;
      margin-bottom: 0.5rem;

      @include md {
        margin-top: 0.5rem;
      }
    }
  }

  .rows {
    p {
      &.indent {
        text-indent: 0.5rem;
      }

      &.link {
        text-indent: 1.2rem;
        margin-top: -1rem;
      }

      &.step {
        margin-bottom: 0.1rem;
      }
    }
  }
}
</style>
